import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { QuestionData } from '../interfaces/QuestionData';

interface QuestionsState {
  readonly loading: boolean;
  readonly unanswered: QuestionData[];
  readonly viewing: QuestionData | null;
  readonly searched: QuestionData[];
}

const initialQuestionState: QuestionsState = {
  loading: false,
  unanswered: [],
  viewing: null,
  searched: [],
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    value: initialQuestionState,
  },
  reducers: {
    gettingUnansweredQuestionsAction: (state) => {
      state.value.loading = true;
    },
    gotUnansweredQuestionsAction: (
      state,
      action: PayloadAction<QuestionData[]>,
    ) => {
      state.value.loading = false;
      state.value.unanswered = action.payload;
    },
    gettingQuestionAction: (state) => {
      state.value.loading = true;
    },
    gotQuestionAction: (state, action: PayloadAction<QuestionData | null>) => {
      state.value.loading = false;
      state.value.viewing = action.payload;
    },
    searchingQuestionsAction: (state) => {
      state.value.loading = true;
    },
    searchedQuestionsAction: (state, action: PayloadAction<QuestionData[]>) => {
      state.value.loading = false;
      state.value.searched = action.payload;
    },
  },
});

export const {
  gettingUnansweredQuestionsAction,
  gotUnansweredQuestionsAction,
  gettingQuestionAction,
  gotQuestionAction,
  searchingQuestionsAction,
  searchedQuestionsAction,
} = questionsSlice.actions;

export default questionsSlice.reducer;
