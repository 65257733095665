import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  } from 'chart.js';
  // import { v4 as uuidv4 } from 'uuid';
//   import { HeatingTemperatureChartData } from './HeatingTemperatureData';
  import { HeatingRequestChartData } from './HeatingRequestData';
  import { Line } from 'react-chartjs-2';
  
  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
  );
  
  export const HeatingRequestChart = ({
    title,
    labels,
    statuses,
  }: HeatingRequestChartData) => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Status',
          data: statuses,
          backgroundColor: '#ff0000',
          borderColor: '#ff0000',
        },
      ],
      // options: {
      //   scales: {
      //     y: {
      //       type: 'linear',
      //       grace: '5%',
      //       min: '10',
      //       max: '65000',
      //     },
      //   },
      //   plugins: {
      //     title: {
      //       display: true,
      //       text: title,
      //     },
      //   },
      // },
    };
  
    const options = {
      responsive: true,
      // scales: {
      //   y: {
      //     type: 'linear',
      //     grace: '5%',
      //     min: '10',
      //     max: '65000',
      //   },
      // },
      // scales: {
      //   y: {},
      // },
      plugins: {
        legend: {
          position: 'top' as const,
        },
        title: {
          display: false,
          text: title,
        },
      },
    };
  
    return (
      <div>
        <h4>{title}</h4>
        <Line options={options} data={data} />
      </div>
    );
  };
  