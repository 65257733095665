import { HeatingRequest } from './HeatingRequestData';
import { TimeCell } from '../common/TimeCell';
import { OnOffStatusCell } from './OnOffStatusCell';

interface Props {
  data: HeatingRequest[];
}

export const HeatingRequestList = ({ data }: Props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Group</th>
          <th>Status</th>
          <th>Begin</th>
          <th>End</th>
          <th>Duration</th>
        </tr>
      </thead>
      <tbody>
        {data.map((record) => (
          <tr key={record.id}>
            <td>{record.group}</td>
            <td>
              <OnOffStatusCell status={record.status}></OnOffStatusCell>
            </td>
            {/* <td>
              <TimeCell time={record.dateBegin.getTime()}></TimeCell>
            </td>
            <td>
              <TimeCell time={record.dateEnd.getTime()}></TimeCell>
            </td> */}
            <td>{record.dateBegin.toString()}</td>
            <td>{record.dateEnd.toString()}</td>
            <td>
              <TimeCell time={record.duration}></TimeCell>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
