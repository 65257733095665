import { Page } from '../../components/Page';
import { HeatingDailyRecordInsertForm } from '../../features/heating/HeatingDailyRecordInsertForm';

export const HeatingDailyRecordInsertPage = () => {
  return (
    <Page title="Heating Daily Record - Insert">
      <HeatingDailyRecordInsertForm />
    </Page>
  );
};
