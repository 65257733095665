import { http } from '../../interfaces/http';
import { webAPIUrl } from '../../AppSettings';
import { IdCode, IdCodeApiManyResponse } from '../../interfaces/commonData';

export interface PatternMaker {
  id: number;
  name: string;
  link: string;
}

export interface PatternMakerApiSingleResponse {
  item: PatternMaker;
  error: string;
}

export interface PatternMakerApiManyResponse {
  items: PatternMaker[];
  error: string;
}

export const getMany = async (): Promise<PatternMakerApiManyResponse> => {
  const result = await http<PatternMaker[]>({
    path: '/sewingPatternMaker',
  });

  const res: PatternMakerApiManyResponse = {
    items: [],
    error: '',
  };

  if (result.ok && result.body) {
    res.items = result.body;
  } else {
    res.error = result.error ?? '';
  }

  return res;
};

export const getManyCombo = async (): Promise<IdCodeApiManyResponse> => {
  const result = await http<IdCode[]>({
    path: 'sewingPatternMaker/combo',
  });

  const res: IdCodeApiManyResponse = {
    items: [],
    error: '',
  };

  if (result.ok && result.body) {
    res.items = result.body;
  } else {
    res.error = result.error ?? '';
  }

  return res;
};

export const getSingle = async (
  id: number,
): Promise<PatternMakerApiSingleResponse> => {
  const result = await http<PatternMaker>({
    path: '/sewingPatternMaker/' + id,
  });

  const res: PatternMakerApiSingleResponse = {
    item: { id: 0, name: '', link: '' },
    error: '',
  };

  if (result.ok && result.body) {
    res.item = result.body;
  } else {
    res.error = result.error ?? '';
  }

  return res;
};

export const postPatternMaker = async (
  name: string,
  link: string,
  token: string | null,
): Promise<PatternMaker | null> => {
  const path = '/sewingPatternMaker';
  const url = `${webAPIUrl}${path}`;
  const result = await fetch(url, {
    method: 'POST',

    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      name: name,
      link: link,
    }),
  });

  if (result.ok && result.body) {
    const data = await result.json();
    return data;
  }

  return null;
};

export const putPatternMaker = async (
  id: number,
  name: string,
  link: string,
  token: string | null,
): Promise<PatternMakerApiSingleResponse | null> => {
  const path = '/sewingPatternMaker';
  const url = `${webAPIUrl}${path}`;
  const result = await fetch(url, {
    method: 'PUT',

    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      id: id,
      name: name,
      link: link,
    }),
  });

  if (result.ok && result.body) {
    const data = await result.json();
    return data;
  }

  return null;
};
