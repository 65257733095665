/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { onColor, offColor } from "../../Styles";
interface Props {
    status: boolean;
};

export const OnOffStatusCell = ({status}: Props) => {
    return (
        <div
            css={css`
                background: ${status ? onColor : offColor}
            `}
        >
            {status ? 'ON' : 'OFF'}
        </div>
    );
};