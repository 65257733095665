/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/Store';
import {
  gettingPatternMakers,
  gotPatternMakers,
} from '../../store/sewingSlice';
import { useState } from 'react';
import { Page } from '../../components/Page';
import { getMany } from '../../features/sewing/PatternMakerData';
import { PatternMakerList } from '../../features/sewing/PatternMakerList';

export const PatternMakerPage = () => {
  const dispatch = useDispatch();

  const patternMakers = useSelector(
    (state: RootState) => state.sewing.value.patternMakers,
  );

  const loading = useSelector((state: RootState) => state.sewing.value.loading);
  const token = useSelector((state: RootState) => state.signin.value.token);

  const [error, setError] = useState('');

  React.useEffect(() => {
    let cancelled = false;

    const doGetPatternMakers = async () => {
      dispatch(gettingPatternMakers());

      var result = await getMany();

      if (!cancelled) {
        dispatch(gotPatternMakers(result.items));
        if (result.error != null && result.error.length > 0) {
          setError(result.error);
        } else {
          setError('');
        }
      }
    };

    doGetPatternMakers();

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Sewing Pattern Makers">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <PatternMakerList data={patternMakers}></PatternMakerList>
          {token && <a href="addPatternMaker">Add new Pattern Maker</a>}
        </React.Fragment>
      )}
    </Page>
  );
};
