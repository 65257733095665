/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import {
  FieldContainer,
  FieldError,
  FieldLabel,
  FieldSet,
  FormButtonContainer,
  PrimaryButton,
  SubmissionFailure,
  SubmissionSuccess,
} from '../../Styles';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ConvertTimeStringToSeconds } from '../../app/GeneralFunctions';
import {
  postingDailyRecord,
  postedDailyRecord,
} from '../../store/heatingSlice';
import { postDailyRecord } from './HeatingDailyRecordData';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';

type FormData = {
  date: Date;
  upTime: string;
  downTime: string;
  togetherTime: string;
  note: string;
};

export const HeatingDailyRecordInsertForm = () => {
  const dispatch = useDispatch();
  const defaultTime = '0:00:00';
  const timeRegex = '(2[0-3])|([0-1]*[0-9]):[0-5]{1}[0-9]{1}:[0-5]{1}[0-9]{1}';
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<FormData>({
    mode: 'onBlur',
  });

  const [searchParams] = useSearchParams();
  const date =
    searchParams.get('date') || new Date().getDate().toLocaleString();
  const upTime = searchParams.get('upTime') || defaultTime;
  const downTime = searchParams.get('downTime') || defaultTime;
  const togetherTime = searchParams.get('togetherTime') || defaultTime;
  const note = searchParams.get('note') || '';

  const [upSeconds, setUpSeconds] = useState(0);
  const [downSeconds, setDownSeconds] = useState(0);
  const [togetherSeconds, setTogetherSeconds] = useState(0);
  const [postError, setPostError] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);

  const token = useSelector((state: RootState) => state.signin.value.token);

  // const [date, setDate] = useState(dateVal);
  // const [upTime, setUpTime] = useState(upTimeVal);
  // const [downTime, setDownTime] = useState(downTimeVal);
  // const [togetherTime, setTogetherTime] = useState(togetherTimeVal);
  // const [note, setNote] = useState(noteVal);

  // const convertUpTime = (e: event) => {
  //   console.log(e);
  //   //setUpSeconds(ConvertTimeStringToSeconds(event.target.value));
  //   //console.log(upSeconds);
  // };

  const submitHandler = async ({
    date,
    upTime,
    downTime,
    togetherTime,
    note,
  }: FormData) => {
    setPostSuccess(false);
    setPostError(false);
    const ups = ConvertTimeStringToSeconds(upTime);
    const downs = ConvertTimeStringToSeconds(downTime);
    const togethers = ConvertTimeStringToSeconds(togetherTime);
    setUpSeconds(ups);
    setDownSeconds(downs);
    setTogetherSeconds(togethers);

    dispatch(postingDailyRecord);

    await postDailyRecord(date, ups, downs, togethers, note, token).then(
      (res) => {
        dispatch(postedDailyRecord(res));
        if (res === null) {
          console.log('INSERT ERROR');
          setPostError(true);
        } else {
          console.log('SUCCESSFULY INSERTED');
          setPostSuccess(true);
        }
      },
    );
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(submitHandler)}>
        <FieldSet>
          <FieldContainer>
            <FieldLabel htmlFor="date" className="form-label">
              Date
            </FieldLabel>
            <input
              {...register('date', { required: true, valueAsDate: true })}
              type="date"
              className="form-control"
              placeholder=""
              id="date"
              name="date"
              defaultValue={date}
              required
            />
          </FieldContainer>
          <FieldContainer>
            <FieldLabel htmlFor="upTime" className="form-label">
              Up
            </FieldLabel>
            <input
              {...register('upTime', {
                required: true,
                pattern: new RegExp(timeRegex),
              })}
              type="text"
              id="upTime"
              name="upTime"
              className="form-control"
              placeholder="0:00:00"
              defaultValue={upTime}
              required
              onChange={(e) =>
                setUpSeconds(ConvertTimeStringToSeconds(e.target.value))
              }
            />
            <span>{upSeconds}s</span>
            {errors.upTime && errors.upTime.type === 'required' && (
              <FieldError>
                You must enter the time value (at least 0:00:00)
              </FieldError>
            )}
            {errors.upTime && errors.upTime.type === 'pattern' && (
              <FieldError>
                Incorrect time format or value. Correct format is 0:00:00.
              </FieldError>
            )}
          </FieldContainer>
          <FieldContainer>
            <FieldLabel htmlFor="downTime" className="form-label">
              Down
            </FieldLabel>
            <input
              {...register('downTime', {
                required: true,
                pattern: new RegExp(timeRegex),
              })}
              type="text"
              id="downTime"
              name="downTime"
              className="form-control"
              placeholder="0:00:00"
              defaultValue={downTime}
              required
              onChange={(e) =>
                setDownSeconds(ConvertTimeStringToSeconds(e.target.value))
              }
            />
            <span>{downSeconds}s</span>
            {errors.downTime && errors.downTime.type === 'required' && (
              <FieldError>
                You must enter the time value (at least 0:00:00)
              </FieldError>
            )}
            {errors.downTime && errors.downTime.type === 'pattern' && (
              <FieldError>
                Incorrect time format or value. Correct format is 0:00:00.
              </FieldError>
            )}
          </FieldContainer>
          <FieldContainer>
            <FieldLabel htmlFor="togetherTime" className="form-label">
              Together
            </FieldLabel>
            <input
              {...register('togetherTime', {
                required: true,
                pattern: new RegExp(timeRegex),
              })}
              type="text"
              id="togetherTime"
              name="togetherTime"
              className="form-control"
              placeholder="0:00:00"
              defaultValue={togetherTime}
              required
              onChange={(e) =>
                setTogetherSeconds(ConvertTimeStringToSeconds(e.target.value))
              }
            />
            <span>{togetherSeconds}s</span>
            {errors.togetherTime && errors.togetherTime.type === 'required' && (
              <FieldError>
                You must enter the time value (at least 0:00:00)
              </FieldError>
            )}
            {errors.togetherTime && errors.togetherTime.type === 'pattern' && (
              <FieldError>
                Incorrect time format or value. Correct format is 0:00:00.
              </FieldError>
            )}
          </FieldContainer>
          <FieldContainer>
            <FieldLabel htmlFor="note" className="form-label">
              Note
            </FieldLabel>
            <input
              {...register('note')}
              type="text"
              id="note"
              name="note"
              className="form-control"
              placeholder=""
              defaultValue={note}
            />
          </FieldContainer>
          <FormButtonContainer>
            <PrimaryButton type="submit">Save</PrimaryButton>
          </FormButtonContainer>
          {postSuccess && (
            <SubmissionSuccess>SUCCESSFULY INSERTED</SubmissionSuccess>
          )}
          {postError && <SubmissionFailure>INSERT ERROR</SubmissionFailure>}
        </FieldSet>
      </form>
    </div>
  );
};
