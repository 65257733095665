import { HeatingTemperature } from './HeatingTemperatureData';

interface Props {
  data: HeatingTemperature[];
}

export const HeatingTemperatureList = ({ data }: Props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Code</th>
          <th>Temperature</th>
        </tr>
      </thead>
      <tbody>
        {data.map((record) => (
          <tr key={record.id}>
            <td>{record.code}</td>
            <td>{(record.temperature / 1000).toFixed(2)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
