/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { HeatingSeasonSumRecord } from './HeatingSeasonSumData';
import { ConvertSecondsToTimeString } from '../../app/GeneralFunctions';
import React from 'react';
// import { gray1, gray5 } from '../../Styles';
import { HeatingTime } from './HeatingTime';

interface Props {
  data: HeatingSeasonSumRecord;
}

export const HeatingSeasonSumList = ({ data }: Props) => {
  let togetherTotal = 0;

  togetherTotal = data.upSum + data.downSum;

  return (
    <React.Fragment>
      <div>
        <table className="table">
          <thead>
            <tr>
              {/* <th>Id</th> */}
              <th>Up Summary</th>
              <th>Up %</th>
              <th>Down Summary</th>
              <th>Down %</th>
              <th>Together</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <HeatingTime data={data.upSum}></HeatingTime>
              </td>
              <td>
                {((data.upSum / (data.upSum + data.downSum)) * 100).toFixed(2)}
              </td>
              <td>
                <HeatingTime data={data.downSum}></HeatingTime>
              </td>
              <td>
                {((data.downSum / (data.upSum + data.downSum)) * 100).toFixed(
                  2,
                )}
              </td>
              <td>
                <HeatingTime data={togetherTotal}></HeatingTime>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* <h4>In Total:</h4>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Up</th>
            <th>Down</th>
            <th>Together</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Time</td>
            <td>{ConvertSecondsToTimeString(upTotal)}</td>
            <td>{ConvertSecondsToTimeString(downTotal)}</td>
            <td>{ConvertSecondsToTimeString(togetherTotal)}</td>
          </tr>
          <tr>
            <td>%</td>
            <td>{((upTotal / (upTotal + downTotal)) * 100).toFixed(2)}</td>
            <td>{((downTotal / (upTotal + downTotal)) * 100).toFixed(2)}</td>
            <td></td>
          </tr>
        </tbody>
      </table> */}
    </React.Fragment>
  );
};
