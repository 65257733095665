import { http } from '../../interfaces/http';
import { webAPIUrl } from '../../AppSettings';

export interface HeatingDailyRecord {
  id: number;
  date: Date;
  up: number;
  down: number;
  together: number;
  note: string;
}

export const getDailyRecords = async (
  year: number,
  month: number,
): Promise<HeatingDailyRecord[]> => {
  const result = await http<HeatingDailyRecord[]>({
    path: `/heatingdailyrecord?year=${year}&month=${month}`,
  });

  if (result.ok && result.body) {
    return result.body.map((record) => ({
      ...record,
      date: new Date(record.date),
    }));
  } else {
    return [];
  }
};

export const postDailyRecord = async (
  date: Date,
  up: number,
  down: number,
  together: number,
  note: string,
  token: string | null,
): Promise<HeatingDailyRecord | null> => {
  const path = '/heatingdailyrecord';
  const url = `${webAPIUrl}${path}`;
  const result = await fetch(url, {
    method: 'POST',

    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      date: date,
      up: up,
      down: down,
      together: together,
      note: note,
    }),
  });

  // let res: SigninResponse = {
  //   token: '',
  //   signedin: false,
  //   expirationTime: '',
  //   errorMessage: '',
  // };
  if (result.ok && result.body) {
    const data = await result.json();
    return data;
  } else {
  }

  return null;
};
