import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { HeatingDailyRecord } from '../features/heating/HeatingDailyRecordData';
import { HeatingTemperature } from '../features/heating/HeatingTemperatureData';
import { HeatingSeasonSumRecord } from '../features/heating/HeatingSeasonSumData';
import { HeatingRequest } from '../features/heating/HeatingRequestData';

interface HeatingState {
  loading: boolean;
  dailyRecords: HeatingDailyRecord[];
  newDailyRecord: HeatingDailyRecord | null;
  temperatures: HeatingTemperature[];
  seasonSum: HeatingSeasonSumRecord;
  heatingRequests: HeatingRequest[];
  errorMessage: string;
}

const initialHeatingState: HeatingState = {
  loading: true,
  dailyRecords: [],
  newDailyRecord: null,
  temperatures: [],
  seasonSum: { year: 0, upSum: 0, downSum: 0 },
  heatingRequests: [],
  errorMessage: '',
};

export const heatingSlice = createSlice({
  name: 'heating',
  initialState: {
    value: initialHeatingState,
  },
  reducers: {
    gettingHeatingDailyRecords: (state) => {
      state.value.loading = true;
    },
    gotHeatingDailyRecords: (
      state,
      action: PayloadAction<HeatingDailyRecord[]>,
    ) => {
      state.value.loading = false;
      state.value.dailyRecords = action.payload;
    },
    postingDailyRecord: (state) => {
      state.value.loading = true;
    },
    postedDailyRecord: (
      state,
      action: PayloadAction<HeatingDailyRecord | null>,
    ) => {
      state.value.loading = false;
      state.value.newDailyRecord = action.payload;
    },
    gettingTemperatures: (state) => {
      state.value.loading = true;
    },
    gotTemperatures: (state, action: PayloadAction<HeatingTemperature[]>) => {
      state.value.loading = false;
      state.value.temperatures = action.payload;
    },
    gettingSeasonSum: (state) => {
      state.value.loading = true;
    },
    gotSeasonSum: (state, action: PayloadAction<HeatingSeasonSumRecord>) => {
      state.value.loading = false;
      state.value.seasonSum = action.payload;
    },
    gettingHeatingRequests: (state) => {
      state.value.loading = true;
    },
    gotHeatingRequests: (state, action: PayloadAction<HeatingRequest[]>) => {
      state.value.loading = false;
      state.value.heatingRequests = action.payload;
    },
  },
});

export const {
  gettingHeatingDailyRecords,
  gotHeatingDailyRecords,
  postingDailyRecord,
  postedDailyRecord,
  gettingTemperatures,
  gotTemperatures,
  gettingSeasonSum,
  gotSeasonSum,
  gettingHeatingRequests,
  gotHeatingRequests,
} = heatingSlice.actions;

export default heatingSlice.reducer;
