/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/Store';
import {
  gettingHeatingDailyRecords,
  gotHeatingDailyRecords,
} from '../../store/heatingSlice';
import { Page } from '../../components/Page';
import { getDailyRecords } from '../../features/heating/HeatingDailyRecordData';
import { HeatingDailyRecordList } from '../../features/heating/HeatingDailyRecordList';

export const HeatingDailyRecordsPage = () => {
  const dailyRecords = useSelector(
    (state: RootState) => state.heating.value.dailyRecords,
  );
  const loading = useSelector(
    (state: RootState) => state.heating.value.loading,
  );
  const dispatch = useDispatch();
  const today = new Date();
  const [year, setYear] = useState(today.getFullYear());
  const [month, setMonth] = useState(today.getMonth() + 1);
  const [disabledNext, setDisabledNext] = useState('disabled');

  //console.log('Year: ' + year);
  //console.log('Month: ' + month);

  React.useEffect(() => {
    let cancelled = false;
    const doGetDailyRecords = async () => {
      dispatch(gettingHeatingDailyRecords());
      var result = await getDailyRecords(year, month);
      if (!cancelled) {
        dispatch(gotHeatingDailyRecords(result));
      }
    };
    doGetDailyRecords();
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, month]);

  const goToNextMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
    setDisabledNext(
      today.getMonth() + 1 === month && today.getFullYear() === year
        ? 'disabled'
        : '',
    );
  };

  const goToPreviousMonth = () => {
    if (month === 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
    setDisabledNext(
      today.getMonth() + 1 === month && today.getFullYear() === year ? 'a' : '',
    );
  };

  return (
    <Page title="Heating Daily Records">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <div>
            <div className="float-start">
              <button className="btn btn-secondary" onClick={goToPreviousMonth}>
                Previous
              </button>
            </div>
            <div
              css={css`
                font-weight: bold;
                text-align: center;
              `}
            >
              {month} / {year}
            </div>
            <div className="float-end">
              <button
                className="btn btn-secondary"
                disabled={disabledNext === 'disabled'}
                onClick={goToNextMonth}
              >
                Next
              </button>
            </div>
          </div>
          <HeatingDailyRecordList data={dailyRecords} />
        </React.Fragment>
      )}
    </Page>
  );
};
