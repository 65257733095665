import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
// import { v4 as uuidv4 } from 'uuid';
import { HeatingTemperatureChartData } from './HeatingTemperatureData';
import { Line } from 'react-chartjs-2';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

export const HeatingTempraturesChart = ({
  title,
  labels,
  sourceTemperatures,
  returnTemperatures,
}: HeatingTemperatureChartData) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Source [°C]',
        data: sourceTemperatures,
        backgroundColor: '#ff0000',
        borderColor: '#ff0000',
      },
      {
        label: 'Return [°C]',
        data: returnTemperatures,
        backgroundColor: '#0000ff',
        borderColor: '#0000ff',
      },
    ],
    // options: {
    //   scales: {
    //     y: {
    //       type: 'linear',
    //       grace: '5%',
    //       min: '10',
    //       max: '65000',
    //     },
    //   },
    //   plugins: {
    //     title: {
    //       display: true,
    //       text: title,
    //     },
    //   },
    // },
  };

  const options = {
    responsive: true,
    // scales: {
    //   y: {
    //     type: 'linear',
    //     grace: '5%',
    //     min: '10',
    //     max: '65000',
    //   },
    // },
    // scales: {
    //   y: {},
    // },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: title,
      },
    },
  };

  return (
    <div>
      <h4>{title}</h4>
      <Line options={options} data={data} />
    </div>
  );
};
