export const server =
  process.env.REACT_APP_ENV === 'production'
    ? 'http://api.hrabje.cz'
    : 'http://localhost:5164';

export const webAPIUrl = `${server}`;

// export const authSettings = {
//   domain: 'dev-l1b948-k.us.auth0.com',
//   client_id: 'dev-l1b948-k',
//   redirect_uri: window.location.origin + '/signin-callback',
//   scope: 'openid profile QandAAPI email',
//   audience: 'https://qanda',
// };
