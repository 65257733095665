import { http } from '../../interfaces/http';

export interface HeatingSeasonSumRecord {
  year: number;
  upSum: number;
  downSum: number;
}

export interface HeatingSeasonSumApiResponse {
  seasonSum: HeatingSeasonSumRecord;
  error: string;
}

export const getSeasonSumRecord = async (
  year: number,
): Promise<HeatingSeasonSumApiResponse> => {
  const result = await http<HeatingSeasonSumRecord>({
    path: `/heatingdailyrecord/seasonsum/${year}`,
  });

  const res: HeatingSeasonSumApiResponse = {
    seasonSum: { year: year, upSum: 0, downSum: 0 },
    error: '',
  };

  if (result.ok && result.body) {
    res.seasonSum = result.body;
  } else {
    res.error = result.error ?? '';
  }

  return res;
};
