import { Page } from '../../components/Page';
import { PatternMakerInsertForm } from '../../features/sewing/PatternMakerInsertForm';

export const PatternMakerInsertPage = () => {
  return (
    <Page title="Pattern Maker - Create New">
      <PatternMakerInsertForm />
    </Page>
  );
};
