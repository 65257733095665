/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/Store';
import { gettingTemperatures, gotTemperatures } from '../../store/heatingSlice';
import { useState } from 'react';
import { Page } from '../../components/Page';
import {
  getLastUpdate,
  getHeatingTemperatureChartData,
  DefaultHeatingTemperatureChartData,
} from '../../features/heating/HeatingTemperatureData';
import { getHeatingGroups } from '../../features/heating/HeatingGroupData';
import { HeatingTemperatureList } from '../../features/heating/HeatingTemperaturesList';
import { HeatingTempraturesChart } from '../../features/heating/HeatingTemperaturesChart';
// import { Chart } from 'chart.js';

export const HeatingTemperaturesPage = () => {
  const dispatch = useDispatch();

  const temperatures = useSelector(
    (state: RootState) => state.heating.value.temperatures,
  );

  const loading = useSelector(
    (state: RootState) => state.heating.value.loading,
  );

  const [refresh, setRefresh] = useState(true);

  const [lastUpdate, setLastUpdate] = useState('');

  const [error, setError] = useState('');

  const [htChartDataUp, setHtChartDataUp] = useState(
    DefaultHeatingTemperatureChartData,
  );
  const [htChartDataDown, setHtChartDataDown] = useState(
    DefaultHeatingTemperatureChartData,
  );

  const doRefresh = () => {
    setRefresh(true);
  };

  React.useEffect(() => {
    let cancelled = false;
    const doGetTemperatures = async () => {
      dispatch(gettingTemperatures());
      var result = await getLastUpdate();
      if (!cancelled) {
        dispatch(gotTemperatures(result.items));
        if (result.items.length > 0) {
          setLastUpdate(result.items[0].lastUpdate);
        } else {
          setLastUpdate('');
        }
        if (result.error != null && result.error.length > 0) {
          setError(result.error);
        } else {
          setError('');
        }

        const groups = await getHeatingGroups();
        //console.log(groups);
        if (groups !== null && groups.length > 1) {
          // setRefresh(false);
          const today = new Date().toISOString().substring(0, 10);
          console.log(today);
          let groupId = groups[0].id;

          const chartDataUp = await getHeatingTemperatureChartData(
            today,
            groupId,
          );
          groupId = groups[1].id;
          setHtChartDataUp(chartDataUp);
          const chartDataDown = await getHeatingTemperatureChartData(
            today,
            groupId,
          );
          setHtChartDataDown(chartDataDown);
        }
      }
    };
    // let interval = null;
    // interval = setInterval(() => {
    //   setRefresh(true);
    // }, 60000);

    if (refresh) {
      doGetTemperatures();
      // clearInterval(interval);
      setRefresh(false);
    }

    // return () => {
    //   cancelled = true;
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // var ctx = document.getElementById('aaa') as HTMLCanvasElement;
  // console.log(ctx);
  // var chartUp = new Chart(ctx, {
  //   type: 'line',
  //   data: {
  //     labels: htChartDataUp.labels,
  //     datasets: [
  //       {
  //         data: htChartDataUp.sourceTemperatures,
  //       },
  //       {
  //         data: htChartDataUp.returnTemperatures,
  //       },
  //     ],
  //   },
  // });
  //console.log(htChartDataUp.sourceTemperatures);
  return (
    <Page title="Heating Temperatures">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <HeatingTemperatureList data={temperatures}></HeatingTemperatureList>
          <div
            css={css`
              font-style: italic;
              float: inline-end;
            `}
          >
            Last Update: {lastUpdate}
          </div>
          <div>
            <button className="btn btn-warning" onClick={doRefresh}>
              Refresh
            </button>
          </div>
          <div className="text-danger">{error}</div>
          <div className="my-2">
            {/* <canvas id="aaa" className="col-12" height="200"></canvas> */}
            <HeatingTempraturesChart
              title={htChartDataUp.title}
              labels={htChartDataUp.labels}
              sourceTemperatures={htChartDataUp.sourceTemperatures}
              returnTemperatures={htChartDataUp.returnTemperatures}
            />
            <HeatingTempraturesChart
              title={htChartDataDown.title}
              labels={htChartDataDown.labels}
              sourceTemperatures={htChartDataDown.sourceTemperatures}
              returnTemperatures={htChartDataDown.returnTemperatures}
            />
          </div>
        </React.Fragment>
      )}
    </Page>
  );
};
