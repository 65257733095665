/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
// import styled from '@emotion/styled';
import { gray1, gray4 } from '../../Styles';
import { ConvertSecondsToTimeString } from '../../app/GeneralFunctions';

interface Props {
  data: number;
}

export const HeatingTime = ({ data }: Props) => {
  // const timeSpan = styled.div`
  //   color: ${data === 0 ? gray5 : gray1};
  // `;

  return (
    <div
      css={css`
        color: ${data === 0 ? gray4 : gray1};
      `}
    >
      {ConvertSecondsToTimeString(data)}
    </div>
  );
};
