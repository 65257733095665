/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/Store';
import { useState } from 'react';
import { Page } from '../../components/Page';
import { HeatingRequestList } from '../../features/heating/HeatingRequestList';
import { HeatingRequestChart } from '../../features/heating/HeatingRequestChart';
import {
  getHeatingRequestChartData,
  DefaultHeatingRequestChartData,
  getHeatingRequests,
} from '../../features/heating/HeatingRequestData';
import { getHeatingGroups } from '../../features/heating/HeatingGroupData';
import {
  gettingHeatingRequests,
  gotHeatingRequests,
} from '../../store/heatingSlice';

export const HeatingRequestPage = () => {
  const dispatch = useDispatch();

  const requests = useSelector(
    (state: RootState) => state.heating.value.heatingRequests,
  );

  const loading = useSelector(
    (state: RootState) => state.heating.value.loading,
  );

  const [refresh, setRefresh] = useState(true);
  const [error, setError] = useState('');

  const [date, setDate] = useState(new Date());
  const [disabledNext, setDisabledNext] = useState('disabled');
  const [reqChartDataUp, setReqChartDataUp] = useState(
    DefaultHeatingRequestChartData,
  );

  const [reqChartDataDown, setReqChartDataDown] = useState(
    DefaultHeatingRequestChartData,
  );

  const doRefresh = () => {
    setRefresh(true);
  };

  React.useEffect(() => {
    let cancelled = false;
    const doGetRequests = async () => {
      if (!cancelled) {
        dispatch(gettingHeatingRequests());

        const ds = date.toISOString().substring(0, 10);

        var result = await getHeatingRequests(ds);
        dispatch(gotHeatingRequests(result.items));

        var chup = await getHeatingRequestChartData(ds, 1);
        setReqChartDataUp(chup);

        var chdown = await getHeatingRequestChartData(ds, 2);
        setReqChartDataDown(chdown);
      }
    };

    if (refresh) {
      doGetRequests();
      setRefresh(false);
    }
  }, [refresh, date]);

  const goToNextDay = () => {
    const today = new Date();
    const d = date;
    d.setDate(d.getDate() + 1);
    setDate(d);
    setRefresh(true);

    setDisabledNext(
      today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear() &&
        today.getDay() === date.getDay()
        ? 'disabled'
        : '',
    );
  };

  const goToPreviousDay = () => {
    const today = new Date();
    const d = date;
    d.setDate(d.getDate() - 1);
    setDate(d);
    setRefresh(true);

    setDisabledNext(
      today.getMonth() === date.getMonth() &&
        today.getFullYear() === date.getFullYear() &&
        today.getDay() === date.getDay()
        ? 'disabled'
        : '',
    );
  };

  return (
    <Page title="Heating Requests">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <div>
            <div className="float-start">
              <button className="btn btn-secondary" onClick={goToPreviousDay}>
                Previous
              </button>
            </div>
            <div
              css={css`
                font-weight: bold;
                text-align: center;
              `}
            >
              {date.toISOString().substring(0, 10)}
            </div>
            <div className="float-end">
              <button
                className="btn btn-secondary"
                disabled={disabledNext === 'disabled'}
                onClick={goToNextDay}
              >
                Next
              </button>
            </div>
          </div>
          <HeatingRequestList data={requests}></HeatingRequestList>
          <div>
            <button className="btn btn-warning" onClick={doRefresh}>
              Refresh
            </button>
          </div>
          <div className="text-danger">{error}</div>
          <div className="my-2">
            <HeatingRequestChart
              title={reqChartDataUp.title}
              labels={reqChartDataUp.labels}
              statuses={reqChartDataUp.statuses}
            />
            <HeatingRequestChart
              title={reqChartDataDown.title}
              labels={reqChartDataDown.labels}
              statuses={reqChartDataDown.statuses}
            />
          </div>
        </React.Fragment>
      )}
    </Page>
  );
};
