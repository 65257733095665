/** @jsxImportSource @emotion/react */
// import { css } from '@emotion/react';
import { HeatingDailyRecord } from './HeatingDailyRecordData';
import { ConvertSecondsToTimeString } from '../../app/GeneralFunctions';
import React from 'react';
// import { gray1, gray5 } from '../../Styles';
import { HeatingTime } from './HeatingTime';

interface Props {
  data: HeatingDailyRecord[];
}

export const HeatingDailyRecordList = ({ data }: Props) => {
  let upTotal = 0;
  let downTotal = 0;
  let togetherTotal = 0;

  data.forEach((record) => {
    upTotal += record.up;
    downTotal += record.down;
    togetherTotal += record.together;
  });

  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            {/* <th>Id</th> */}
            <th>Date</th>
            <th>Up</th>
            <th>Down</th>
            <th>Together</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {data.map((record) => (
            <tr key={record.id}>
              {/* <td>{record.id}</td> */}
              <td>{record.date.toLocaleDateString()}</td>
              <td>
                <HeatingTime data={record.up}></HeatingTime>
              </td>
              <td>
                <HeatingTime data={record.down}></HeatingTime>
              </td>
              <td>
                <HeatingTime data={record.together}></HeatingTime>
              </td>
              <td>{record.note}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <h4>In Total:</h4>
      <table className="table">
        <thead>
          <tr>
            <th></th>
            <th>Up</th>
            <th>Down</th>
            <th>Together</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Time</td>
            <td>{ConvertSecondsToTimeString(upTotal)}</td>
            <td>{ConvertSecondsToTimeString(downTotal)}</td>
            <td>{ConvertSecondsToTimeString(togetherTotal)}</td>
          </tr>
          <tr>
            <td>%</td>
            <td>{((upTotal / (upTotal + downTotal)) * 100).toFixed(2)}</td>
            <td>{((downTotal / (upTotal + downTotal)) * 100).toFixed(2)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};
