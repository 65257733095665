/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/Store';
import { gettingSeasonSum, gotSeasonSum } from '../../store/heatingSlice';
import { Page } from '../../components/Page';
import { getSeasonSumRecord } from '../../features/heating/HeatingSeasonSumData';
import { HeatingSeasonSumList } from '../../features/heating/HeatingSeasonSumList';

export const HeatingSeasonStatisticsPage = () => {
  const seasonSums = useSelector(
    (state: RootState) => state.heating.value.seasonSum,
  );
  const loading = useSelector(
    (state: RootState) => state.heating.value.loading,
  );
  const dispatch = useDispatch();
  const today = new Date();
  let yr = today.getFullYear();
  const mnt = today.getMonth();
  if (mnt <= 6) {
    yr = yr - 1;
  }
  const [year, setYear] = useState(yr);
  // const [month, setMonth] = useState(today.getMonth());
  const [disabledNext, setDisabledNext] = useState('disabled');

  React.useEffect(() => {
    let cancelled = false;
    const doGetSeasonSum = async () => {
      dispatch(gettingSeasonSum());
      var result = await getSeasonSumRecord(year);
      if (!cancelled) {
        dispatch(gotSeasonSum(result.seasonSum));
      }
    };
    doGetSeasonSum();
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  const goToNextYear = () => {
    const y = year + 1;
    setYear(y);
    setDisabledNext(today.getFullYear() === year ? 'disabled' : '');
  };

  const goToPreviousYear = () => {
    const y = year - 1;
    setYear(y);
    setDisabledNext(today.getFullYear() === year ? 'a' : '');
  };

  return (
    <Page title="Heating Season Statistics">
      {loading ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-6 float-start">
              <button className="btn btn-secondary" onClick={goToPreviousYear}>
                Previous
              </button>
            </div>
            <div
              css={css`
                font-weight: bold;
                text-align: center;
              `}
            ></div>

            <div className="col-sm-6 float-end">
              <button
                className="btn btn-secondary"
                disabled={disabledNext === 'disabled'}
                onClick={goToNextYear}
              >
                Next
              </button>
            </div>
          </div>
          <h4>
            Season: {year} / {year + 1}
          </h4>
          <HeatingSeasonSumList data={seasonSums} />
        </React.Fragment>
      )}
    </Page>
  );
};
