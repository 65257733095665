/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import React from 'react';
import { PageTitle } from './PageTitle';

interface Props {
  title?: string;
  children: React.ReactNode;
}

export const Page = ({ title, children }: Props) => (
  <div
    css={css`
      margin: auto;
      margin-top: 77px;
      padding: 20px 20px;
      max-width: 1000px;
    `}
  >
    {title && <PageTitle>{title}</PageTitle>}
    {children}
  </div>
);
