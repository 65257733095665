import { useSelector } from 'react-redux';
import { RootState } from '../store/Store';
import { useDispatch } from 'react-redux';
import { logout } from '../store/signinSlice';
import { useEffect, useState } from 'react';

const calculateRemainingTime = (expirationTime: string) => {
  const currentTime = new Date().getTime();
  const expTime = new Date(expirationTime).getTime();

  //console.log('Current Time: ' + currentTime);
  //console.log('Expires at: ' + expTime);
  const remainingDuration = expTime - currentTime;

  return remainingDuration;
};

let timer: NodeJS.Timeout;

export const AppLogout = () => {
  const isSignedIn = useSelector(
    (state: RootState) => state.signin.value.signedin,
  );
  const expirationTime = useSelector(
    (state: RootState) => state.signin.value.expirationTime,
  );

  const [isTime, setIsTime] = useState(true);
  const dispatch = useDispatch();

  // const logoutHandler = () => {
  //   dispatch(logout());
  // };

  useEffect(() => {
    if (isSignedIn) {
      const remains = calculateRemainingTime(expirationTime);
      //console.log(expirationTime);
      //console.log('Rem: ' + remains);
      if (remains < 1) {
        //console.log('minus');
        dispatch(logout());
      } else {
        timer = setTimeout(() => {
          setIsTime(true);
        }, remains);
      }
    } else {
      clearTimeout(timer);
    }
  }, [isSignedIn, expirationTime, isTime]);

  // if (isSignedIn) {
  //   const remains = calculateRemainingTime(expirationTime);
  //   //console.log(expirationTime);
  //   //console.log('Rem: ' + remains);
  //   if (remains < 1) {
  //     //console.log('minus');
  //     logoutHandler();
  //   } else {
  //     setTimeout(logoutHandler, remains);
  //   }
  // }

  return <div></div>;
};
