import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PatternMaker } from '../features/sewing/PatternMakerData';
import { PatternFor } from '../features/sewing/PatternForData';
import { IdCode } from '../interfaces/commonData';

interface SewingState {
    loading: boolean;
    patternMaker: PatternMaker | null;
    newPatternMaker: PatternMaker | null;
    patternMakers: PatternMaker[];
    patternMakersCombo: IdCode[];
    
    patternFors: PatternFor[];
    
    errorMessage: string;
};

const initialSewingState: SewingState = {
    loading: true,
    patternMaker: null,
    newPatternMaker: null,
    patternMakers: [],
    patternMakersCombo: [],

    patternFors: [],

    errorMessage: '',
};

export const sewingSlice = createSlice({
    name: 'sewing',
    initialState: {
        value: initialSewingState,
    },
    reducers: {
        gettingPatternMaker: (state) => {
            state.value.loading = true;
        },
        gotPatternMaker: (
            state,
            action: PayloadAction<PatternMaker>,
        ) => {
            state.value.loading = false;
            state.value.patternMaker = action.payload;
        },
        gettingPatternMakers: (state) => {
            state.value.loading = true;
        },
        gotPatternMakers: (
            state,
            action: PayloadAction<PatternMaker[]>,
        ) => {
            state.value.loading = false;
            state.value.patternMakers = action.payload;
        },
        gettiingPatternMakersCombo: (state) => {
            state.value.loading = true;
        },
        gotPatternMakersCombo: (
            state,
            action: PayloadAction<IdCode[]>,
        ) => {
            state.value.loading = false;
            state.value.patternMakersCombo = action.payload;
        },
        postingPatternMaker: (state) => {
            state.value.loading = true;
        },
        postedPatternMaker: (
            state,
            action: PayloadAction<PatternMaker | null>,
        ) => {
            state.value.loading = false;
            state.value.newPatternMaker = action.payload;
        },
        puttingPatternMaker: (state) => {
            state.value.loading = true;
        },
        putPatternMaker: (
            state,
            action: PayloadAction<PatternMaker | null>
        ) => {
            state.value.loading = false;
            state.value.patternMaker = action.payload;
        },

        gettingPatternFors: (state) => {
            state.value.loading = true;
        },
        gotPatternFors: (
            state,
            action: PayloadAction<PatternFor[]>,
        ) => {
            state.value.loading = false;
            state.value.patternFors = action.payload;
        },
    },
});

export const {
    gettingPatternMaker,
    gotPatternMaker,
    gettingPatternMakers,
    gotPatternMakers,
    gettiingPatternMakersCombo,
    gotPatternMakersCombo,
    postingPatternMaker,
    postedPatternMaker,
    puttingPatternMaker,
    putPatternMaker,

    gettingPatternFors,
    gotPatternFors,
} = sewingSlice.actions;

export default sewingSlice.reducer;
