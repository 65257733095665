import { Link } from 'react-router-dom';
import { Page } from '../../components/Page';

export const SewingPage = () => {
  return (
    <Page title="Sewing">
      <Link to="./patternMakers">Pattern Makers</Link>
      <br />
      <Link to="./patterns">Patterns</Link>
      <br />
      {/* <Link to="./seasonsum">Season Summary</Link> */}
    </Page>
  );
};
