import React from 'react';
import { Page } from '../components/Page';
import { useForm } from 'react-hook-form';
import { useSearchParams, useNavigate } from 'react-router-dom';
import {
  FieldContainer,
  FieldLabel,
  FieldSet,
  FormButtonContainer,
  PrimaryButton,
  SubmissionSuccess,
} from '../Styles';
import { doSignin } from '../features/signin/SigninData';
import { gettingSignin, gotSignin, logout } from '../store/signinSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

type FormData = {
  username: string;
  password: string;
};

const calculateRemainingTime = (expirationTime: string) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();

  const remainingDuration = adjExpirationTime - currentTime;

  return remainingDuration;
};

export const SignInPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<FormData>();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username') || '';
  const password = searchParams.get('password') || '';
  const [response, setResponse] = useState('');
  const [signinRequest, setSigninRequest] = useState(false);

  const doLogout = () => {
    dispatch(logout);
  };

  const submitForm = async ({ username, password }: FormData) => {
    await doSignin(username, password).then((res) => {
      if (res.signedin) {
        dispatch(gotSignin(res));
        var remainingTime = calculateRemainingTime(res.expirationTime);
        setTimeout(doLogout, 3000);
        navigate('/');
      } else {
        setResponse(res.errorMessage);
      }
    });
    // setSigninRequest(true);
  };

  return (
    <Page title="Sign In">
      <div className="form-container">
        <form onSubmit={handleSubmit(submitForm)}>
          <FieldSet>
            <FieldContainer>
              <FieldLabel htmlFor="username">Username</FieldLabel>
              <input
                {...register('username')}
                type="text"
                placeholder="Username"
                name="username"
                defaultValue={username}
                className="form-control"
                required
              />
            </FieldContainer>
            {/* <label htmlFor="password" className="form-label">
            Password
          </label> */}
            <FieldContainer>
              <FieldLabel htmlFor="password">Password</FieldLabel>
              <input
                {...register('password')}
                type="password"
                placeholder="Password"
                name="password"
                defaultValue={password}
                className="form-control"
                required
              />
            </FieldContainer>
            <FormButtonContainer>
              <PrimaryButton type="submit">Sign In</PrimaryButton>
            </FormButtonContainer>
          </FieldSet>
        </form>
        <div className="text-danger ">{response}</div>
      </div>
    </Page>
  );
};
