/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { fontFamily, fontSize, gray2 } from './Styles';
import React from 'react';
// import { configureStore } from './app/Store';
import { RootState } from './store/Store';
import { Header } from './components/Header';
import { HomePage } from './pages/HomePage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { AskPage } from './pages/AskPage';
import { SearchPage } from './pages/SearchPage';
import { SignInPage } from './pages/SignInPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { QuestionPage } from './pages/QuestionPage';
import { TimeConversionPage } from './pages/TimeConversionPage';

import { HeatingPage } from './pages/heating/HeatingPage';
import { HeatingDailyRecordsPage } from './pages/heating/HeatingDailyRecordsPage';
import { HeatingDailyRecordInsertPage } from './pages/heating/HeatingDailyRecordInsertPage';
import { HeatingTemperaturesPage } from './pages/heating/HeatingTemperaturesPage';
import { HeatingSeasonStatisticsPage } from './pages/heating/HeatingSeasonStatisticsPage';

import { HeatingRequestPage } from './pages/heating/HeatingRequestPage';

import { SewingPage } from './pages/sewing/SewingPage';

import { useSelector } from 'react-redux';

import { AppLogout } from './components/AppLogout';
import { PatternMakerPage } from './pages/sewing/PatternMakerPage';
import { PatternMakerInsertPage } from './pages/sewing/PatternMakerInsertPage';

const AskPage = React.lazy(() => import('./pages/AskPage'));

// const store = configureStore();

function App() {
  const isSignedIn = useSelector(
    (state: RootState) => state.signin.value.signedin,
  );
  return (
    <BrowserRouter>
      <div
        css={css`
          font-family: ${fontFamily};
          font-size: ${fontSize};
          color: ${gray2};
        `}
      >
        <AppLogout />
        <Header />
        {/* <Menu /> */}
        <Routes>
          <Route path="" element={<HomePage />} />
          <Route path="search" element={<SearchPage />} />
          <Route
            path="ask"
            element={
              <React.Suspense
                fallback={
                  <div
                    css={css`
                      margin-top: 10px;
                      text-align: center;
                      background-color: red;
                    `}
                  >
                    Loading...
                  </div>
                }
              >
                <AskPage />
              </React.Suspense>
            }
          />
          <Route path="signin" element={<SignInPage />} />
          <Route path="questions/:questionId" element={<QuestionPage />} />
          <Route path="timeconversion" element={<TimeConversionPage />} />

          <Route path="heating" element={<HeatingPage />} />
          <Route
            path="heating/heatingdaily"
            element={<HeatingDailyRecordsPage />}
          />
          {isSignedIn && (
            <Route
              path="heating/heatingdaily/insert"
              element={<HeatingDailyRecordInsertPage />}
            ></Route>
          )}
          <Route
            path="heating/temperatures"
            element={<HeatingTemperaturesPage />}
          />
          <Route
            path="heating/requests"
            element={<HeatingRequestPage />}
          />
          <Route
            path="heating/seasonsum"
            element={<HeatingSeasonStatisticsPage />}
          />

          <Route path="sewing" element={<SewingPage />} />
          <Route path="sewing/patternMakers" element={<PatternMakerPage />} />
          <Route
            path="sewing/addPatternMaker"
            element={<PatternMakerInsertPage />}
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
