/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { fontFamily, fontSize, gray1, gray2, gray5 } from '../Styles';
import React from 'react';
import { PersonIcon } from './Icons';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RootState } from '../store/Store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { logout } from '../store/signinSlice';
import { Menu } from './Menu';

type FormData = {
  search: string;
};

export const Header = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<FormData>();
  const [searchParams] = useSearchParams();
  const criteria = searchParams.get('criteria') || '';

  const isSignedIn = useSelector(
    (state: RootState) => state.signin.value.signedin,
  );

  const dispatch = useDispatch();
  // const [search, setSearch] = React.useState(criteria);

  // const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   // console.log(e.currentTarget.value);
  //   setSearch(e.currentTarget.value);
  // };

  const submitForm = ({ search }: FormData) => {
    navigate(`search?criteria=${search}`);
    // console.log(search);
  };

  return (
    <div
      css={css`
        position: fixed;
        box-sizing: border-box;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background-color: #fff;
        border-bottom: 1px solid ${gray5};
        box-shadow: 0 3px 7px 0 rgba(110, 112, 114, 0.21);
      `}
    >
      {/* <Link
        to="./"
        css={css`
          font-size: 24px;
          font-weight: bold;
          color: ${gray1};
          text-decoration: none;
        `}
      >
        hrabje.cz
        <span
          css={css`
            margin-left: 5px;
            font-size: 14px;
            font-weight: normal;
          `}
        >
          {process.env.REACT_APP_ENV ? '' : 'dev'}
        </span>
      </Link> */}
      <Menu />
      <form onSubmit={handleSubmit(submitForm)}>
        <input
          {...register('search')}
          name="search"
          type="text"
          placeholder="Search..."
          defaultValue={criteria}
          css={css`
            box-sizing: border-box;
            font-family: ${fontFamily};
            font-size: ${fontSize};
            padding: 8px 10px;
            border: 1px solid ${gray5};
            border-radius: 3px;
            color: ${gray2};
            background-color: white;
            width: 200px;
            height: 30px;
          `}
        />
      </form>

      {!isSignedIn && (
        <Link
          to="./signin"
          css={css`
            font-family: ${fontFamily};
            font-size: ${fontSize};
            padding: 5px 10px;
            background-color: transparent;
            color: ${gray2};
            text-decoration: none;
            cursor: pointer;
            :focus {
              outline-color: ${gray5};
            }
            span {
              margin-left: 7px;
            }
          `}
        >
          <PersonIcon />
          <span>Sign In</span>
        </Link>
      )}

      {isSignedIn && (
        <Link
          to="#"
          css={css`
            font-family: ${fontFamily};
            font-size: ${fontSize};
            padding: 5px 10px;
            background-color: transparent;
            color: ${gray2};
            text-decoration: none;
            cursor: pointer;
            :focus {
              outline-color: ${gray5};
            }
            span {
              margin-left: 7px;
            }
          `}
          onClick={() => {
            dispatch(logout());
          }}
        >
          <PersonIcon />
          <span>Log Out</span>
        </Link>
      )}
    </div>
  );
};
