//import { http } from '../../interfaces/http';
import { webAPIUrl } from '../../AppSettings';

export interface SigninResponse {
  token: string;
  signedin: boolean;
  expirationTime: string; //seconds
  errorMessage: string;
}

export const doSignin = async (
  username: string,
  password: string,
): Promise<SigninResponse> => {
  const path = '/login';
  const url = `${webAPIUrl}${path}`;
  const result = await fetch(url, {
    method: 'POST',

    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  //let res: string = '';
  let res: SigninResponse = {
    token: '',
    signedin: false,
    expirationTime: '',
    errorMessage: '',
  };
  if (result.ok && result.body) {
    const data = await result.json();
    res.token = data.token;
    const expirationTime = new Date(
      new Date().getTime() + +data.expiresIn * 1000,
    );
    res.expirationTime = expirationTime.toISOString();
    res.signedin = true;
  } else {
    res.token = '';
    res.errorMessage = await result.text();
    res.signedin = false;
  }

  return res;
};
