export const timeRegex = '^[0-9]{1,2}:[0-9]{1,2}:[0-9]{1,2}$';

export const ConvertSecondsToTimeString = (seconds: number): string => {
  let result = '';

  const days = Math.floor(seconds / 86400);
  seconds = seconds % 86400;
  const hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  if (days > 0) {
    result += days + 'd ';
  }
  result += hours + ':';
  if (minutes < 10) result += '0';
  result += minutes + ':';
  if (seconds < 10) result += '0';
  result += seconds;

  return result;
};

export const ConvertTimeStringToSeconds = (time: string): number => {
  const arr = time.split(':');
  let result = 0;

  if (arr.length < 3) return result;

  result += +arr[0] * 3600;
  result += +arr[1] * 60;
  result += +arr[2];

  return result;
};
