import { http } from '../../interfaces/http';

export interface HeatingGroup {
    id: number;
    name: string;
};

export const getHeatingGroups = async (): Promise<HeatingGroup[]> => {
    const result = await http<HeatingGroup[]>({
      path: '/HeatingGroup',
    });
  
    if (result.ok && result.body) {
      return result.body;
    } else {
      const empty: HeatingGroup[] = [
        {
          id: 0,
          name: 'error',
        },
      ];
      return empty;
    }
  };