/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import {
  FieldContainer,
  FieldError,
  FieldLabel,
  FieldSet,
  FormButtonContainer,
  PrimaryButton,
  SubmissionFailure,
  SubmissionSuccess,
} from '../../Styles';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  postingPatternMaker,
  postedPatternMaker,
} from '../../store/sewingSlice';
import { postPatternMaker } from './PatternMakerData';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/Store';

type FormData = {
  name: string;
  link: string;
};

export const PatternMakerInsertForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    formState,
  } = useForm<FormData>({
    mode: 'onBlur',
  });

  const [searchParams] = useSearchParams();
  const name = searchParams.get('name') || '';
  const link = searchParams.get('link') || '';

  const [postError, setPostError] = useState(false);
  const [postSuccess, setPostSuccess] = useState(false);

  const token = useSelector((state: RootState) => state.signin.value.token);

  const submitHandler = async ({ name, link }: FormData) => {
    setPostSuccess(false);
    setPostError(false);

    dispatch(postingPatternMaker);

    await postPatternMaker(name, link, token).then((res) => {
      dispatch(postedPatternMaker(res));
      if (res === null) {
        console.log('INSERT ERROR');
        setPostError(true);
      } else {
        console.log('SUCCESSFULY INSERTED');
        setPostSuccess(true);
      }
    });
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit(submitHandler)}>
        <FieldSet>
          <FieldContainer>
            <FieldLabel htmlFor="name" className="form-label">
              Name
            </FieldLabel>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder=""
              defaultValue={name}
              required
            />
            {errors.name && errors.name.type === 'required' && (
              <FieldError>You muset enter the Name</FieldError>
            )}
          </FieldContainer>
          <FieldContainer>
            <FieldLabel htmlFor="link" className="form-label">
              Link
            </FieldLabel>
            <input
              {...register('link', { required: false })}
              type="text"
              id="link"
              name="link"
              className="form-control"
              placeholder=""
              defaultValue={link}
            />
          </FieldContainer>
          <FormButtonContainer>
            <PrimaryButton type="submit">Save</PrimaryButton>
          </FormButtonContainer>
          {postSuccess && (
            <SubmissionSuccess>Successfuly inserted</SubmissionSuccess>
          )}
          {postError && <SubmissionFailure>INSERT ERROR</SubmissionFailure>}
        </FieldSet>
      </form>
    </div>
  );
};
