import { http } from '../../interfaces/http';
// import { webAPIUrl } from '../../AppSettings';

export interface HeatingTemperature {
  id: number;
  sensorId: string;
  code: string;
  temperature: number;
  lastUpdate: string;
}

export interface HeatingTemperatureApiResponse {
  items: HeatingTemperature[];
  error: string;
}

export interface HeatingTemperatureChartData {
  title: string;
  labels: string[];
  sourceTemperatures: number[];
  returnTemperatures: number[];
}

export const DefaultHeatingTemperatureChartData: HeatingTemperatureChartData = {
  title: '',
  labels: [],
  sourceTemperatures: [],
  returnTemperatures: [],
};


// export const getLastUpdate = async (): Promise<HeatingTemperature[]> => {
//   const result = await http<HeatingTemperature[]>({
//     path: '/heatingtemperature/lastUpdate',
//   });

//   if (result.ok && result.body) {
//     return result.body;
//   } else {
//     return [];
//   }
// };

export const getLastUpdate =
  async (): Promise<HeatingTemperatureApiResponse> => {
    const result = await http<HeatingTemperature[]>({
      path: '/heatingtemperature/lastUpdate',
    });

    const res: HeatingTemperatureApiResponse = {
      items: [],
      error: '',
    };

    if (result.ok && result.body) {
      res.items = result.body;
    } else {
      res.error = result.error ?? '';
    }

    return res;
  };

export const getHeatingTemperatureChartData = async (
  date: string,
  groupId: number,
): Promise<HeatingTemperatureChartData> => {
  const path =
    '/heatingtemperature/chartdata?date=' + date + '&groupId=' + groupId;
  // const url = `${webAPIUrl}${path}`;
  // const result = await fetch(url, {
  //   method: 'GET',

  //   headers: {
  //     'Content-type': 'application/json; charset=UTF-8',
  //     //Authorization: 'Bearer ' + token,
  //   },
  //   body: JSON.stringify({
  //     date: date,
  //     groupId: groupId,
  //   }),
  // });
  const result = await http<HeatingTemperatureChartData>({
    path: path,
  });

  if (result.ok && result.body) {
    return result.body;
  } else {
    let err = DefaultHeatingTemperatureChartData;
    err.title = 'Error';
    return err;
  }
};

