/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Page } from '../components/Page';
import {
  FieldContainer,
  FieldLabel,
  FieldInputShort,
  FormButtonContainer,
  PrimaryButton,
} from '../Styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

type FormData = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

const convertToMinutes = (time: FormData): number => {
  return (
    time.days * 1440 + time.hours * 60 + time.minutes * 1 + time.seconds / 60
  );
};

const convertToSeconds = (
  // days: number,
  // hours: number,
  // minutes: number,
  // seconds: number,
  time: FormData,
): number => {
  // let result = 0;
  // result += time.days * 86400;
  // console.log(result);
  // result += time.hours * 3600;
  // console.log(result);
  // result += time.minutes * 60;
  // console.log(result);
  // result += time.seconds * 1;
  // console.log(result);

  return (
    time.days * 86400 + time.hours * 3600 + time.minutes * 60 + time.seconds * 1
    //result
  );
};

export const TimeConversionPage = () => {
  const { register, handleSubmit } = useForm<FormData>();
  const [inMinutes, setInMinutes] = useState<number>(0);
  const [inSeconds, setInSeconds] = useState<number>(0);
  // let inSeconds: number = 0;
  // let inMinutes: number = 0;

  // const days = 0;
  // const hours = 0;
  // const minutes = 0;
  // const seconds = 0;

  const convert = (time: FormData) =>
    // days: number,
    // hours: number,
    // minutes: number,
    // seconds: number,
    {
      setInMinutes(convertToMinutes(time));
      setInSeconds(convertToSeconds(time));
    };

  return (
    <Page title="Time Conversion">
      <React.Fragment>
        <form onSubmit={handleSubmit(convert)}>
          <FieldContainer>
            <FieldLabel htmlFor="days">Days</FieldLabel>
            <FieldInputShort
              {...register('days')}
              id="days"
              defaultValue={0}
            ></FieldInputShort>
            <FieldLabel htmlFor="hours">Hours</FieldLabel>
            <FieldInputShort
              {...register('hours')}
              id="hours"
              defaultValue={0}
            ></FieldInputShort>
            <FieldLabel htmlFor="minutes">Minutes</FieldLabel>
            <FieldInputShort
              {...register('minutes')}
              id="minutes"
              defaultValue={0}
            ></FieldInputShort>
            <FieldLabel htmlFor="seconds">Seconds</FieldLabel>
            <FieldInputShort
              {...register('seconds')}
              id="seconds"
              defaultValue={0}
            ></FieldInputShort>
            <PrimaryButton>Count</PrimaryButton>
          </FieldContainer>
        </form>

        <div>
          <div>
            Seconds:{' '}
            <span
              css={css`
                font-size: 24px;
                font-weight: bold;
              `}
            >
              {inSeconds}
            </span>
          </div>
          <div>Minutes: {inMinutes}</div>
        </div>
      </React.Fragment>
    </Page>
  );
};
