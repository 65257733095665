/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { gray1, gray4 } from '../../Styles';
import { ConvertSecondsToTimeString } from '../../app/GeneralFunctions';

interface Props {
  time: number;
}

export const TimeCell = ({ time }: Props) => {
    return (
    <div
      css={css`
        color: ${time === 0 ? gray4 : gray1};
      `}
    >
      {ConvertSecondsToTimeString(time)}
    </div>
  );
};
