import { http } from "../../interfaces/http";

export interface HeatingRequest {
    id: number;
    groupId: number;
    group: string;
    status: boolean;
    dateBegin: Date;
    dateEnd: Date;
    duration: number;
};

export interface HeatingRequestApiResponse {
    items: HeatingRequest[];
    error: string;
};

export interface HeatingRequestChartData {
    title: string;
    labels: string[];
    statuses: boolean[];
};

export const DefaultHeatingRequestChartData: HeatingRequestChartData = {
    title: '',
    labels: [],
    statuses: [],
};

export const getHeatingRequestChartData = async (
    date: string,
    groupId: number,
): Promise<HeatingRequestChartData> => {
    const path = '/heatinglogger/chartdata?date=' + date + '&groupId=' + groupId;

    const result = await http<HeatingRequestChartData>({
        path: path,
    });

    if(result.ok && result.body) {
        return result.body;
    }
    else {
        let err = DefaultHeatingRequestChartData;
        err.title = "Error"

        return err;
    }
};

export const getHeatingRequests = async (
    date: string
): Promise<HeatingRequestApiResponse> => {
    const path = '/heatinglogger/requests?date=' + date;

    const result = await http<HeatingRequest[]>({
        path: path,
    });

    const res: HeatingRequestApiResponse = {
        items: [],
        error: '',
    };

    if(result.ok && result.body) {
        res.items = result.body;
    }
    else {
        res.error = result.error ?? '';
    }

    return res;
};
