import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SigninResponse } from '../features/signin/SigninData';

interface SigninState {
  loading: boolean;
  token: string | null;
  signedin: boolean;
  expirationTime: string;
  errorMessage: string;
}

const initialSigninState: SigninState = {
  loading: true,
  token: localStorage.getItem('userToken'),
  signedin: localStorage.getItem('userToken') !== null,
  expirationTime: localStorage.getItem('userTokenExpiresIn') ?? '',
  errorMessage: '',
};

export const signinSlice = createSlice({
  name: 'signinSlice',
  initialState: {
    value: initialSigninState,
  },
  reducers: {
    logout: (state) => {
      state.value.token = '';
      state.value.signedin = false;
      state.value.expirationTime = '';
      localStorage.removeItem('userToken');
    },
    gettingSignin: (state) => {
      state.value.loading = true;
    },
    gotSignin: (state, action: PayloadAction<SigninResponse>) => {
      state.value.loading = false;
      state.value.token = action.payload.token;
      state.value.signedin = action.payload.signedin;
      if (!action.payload.signedin)
        state.value.errorMessage = action.payload.errorMessage; //Token v tomto pripade obsahuje chybovou hlasku
      state.value.expirationTime = action.payload.expirationTime;
      localStorage.setItem('userToken', action.payload.token);
      localStorage.setItem('userTokenExpiresIn', action.payload.expirationTime);
    },
  },
});

export const { gettingSignin, gotSignin, logout } = signinSlice.actions;

export default signinSlice.reducer;
