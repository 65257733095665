// import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Page } from '../../components/Page';
// import { HeatingDailyRecordsPage } from './HeatingDailyRecordsPage';

export const HeatingPage = () => {
  // const navigate = useNavigate();

  return (
    <Page title="Heating">
      <Link to="./heatingdaily">Heating Daily Records</Link>
      <br />
      <Link to="./requests">Requests</Link>
      <br />
      <Link to="./temperatures">Heating Temperatures</Link>
      <br />
      <Link to="./seasonsum">Season Summary</Link>
    </Page>
  );
};
