/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import person from '../icons/person.svg';

export const PersonIcon = () => (
  <img
    src={person}
    alt="User"
    css={css`
      width: 12px;
      opacity: 0.6;
    `}
  />
);
