/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { PatternMaker } from './PatternMakerData';

interface Props {
  data: PatternMaker[];
}

export const PatternMakerList = ({ data }: Props) => {
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>Name</tr>
          <tr></tr>
        </thead>
        <tbody>
          {data.map((record) => (
            <tr key={record.id}>
              <td>{record.name}</td>
              <td>
                {record.link && (
                  <a href={record.link} target="_blank" rel="noreferrer">
                    Official Site
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};
