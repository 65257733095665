/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import {
//   gettingUnansweredQuestionsAction,
//   gotUnansweredQuestionsAction,
//   AppState,
// } from '../interfaces/Store_OLD';
import { QuestionList } from '../components/QuestionList';
import { getUnansweredQuestions } from '../interfaces/QuestionData';
import { Page } from '../components/Page';
import { PageTitle } from '../components/PageTitle';
import { PrimaryButton } from '../Styles';
import { useNavigate } from 'react-router-dom';
import {
  gettingUnansweredQuestionsAction,
  gotUnansweredQuestionsAction,
} from '../store/questionsSlice';
import { RootState } from '../store/Store';

export const HomePage = () => {
  const dispatch = useDispatch();
  const questions = useSelector(
    (state: RootState) => state.questions.value.unanswered,
  );
  const questionsLoading = useSelector(
    (state: RootState) => state.questions.value.loading,
  );
  // const [questions, setQuestions] = React.useState<QuestionData[]>([]);
  // const [questionsLoading, setQuestionsLoading] = React.useState(true);

  React.useEffect(() => {
    let cancelled = false;
    const doGetUnansweredQuestions = async () => {
      dispatch(gettingUnansweredQuestionsAction());
      const unansweredQuestions = await getUnansweredQuestions();
      if (!cancelled) {
        dispatch(gotUnansweredQuestionsAction(unansweredQuestions));
      }
      // setQuestions(unansweredQuestions);
      // setQuestionsLoading(false);
    };
    doGetUnansweredQuestions();
    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  const handleTestButtonClick = () => {
    // console.log('Test Button clicked');
    // setQuestionsLoading(true);
    navigate('ask');
  };

  return (
    <Page>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <PageTitle>hrabje's website</PageTitle>
        <PrimaryButton onClick={handleTestButtonClick}>
          Test Button
        </PrimaryButton>
      </div>
      {questionsLoading ? (
        <div>Loading...</div>
      ) : (
        <QuestionList data={questions} />
      )}
    </Page>
  );
};
