/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { RootState } from '../store/Store';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { gray1 } from '../Styles';

export const Menu = () => {
  const isSignedIn = useSelector(
    (state: RootState) => state.signin.value.signedin,
  );

  return (
    <nav
      // css={css`
      //   margin-top: 50px;
      //   padding: 10px;
      //   color: black;
      // `}
      className="navbar navbar-expand-sm bg-body-tertiary"
    >
      <div className="container-fluid">
        <Link
          to="./"
          css={css`
            font-size: 24px;
            font-weight: bold;
            padding: 0px 10px 0px 10px;
            color: ${gray1};
            text-decoration: none;
          `}
        >
          hrabje.cz
          <span
            css={css`
              margin-left: 5px;
              font-size: 14px;
              font-weight: normal;
            `}
          >
            {process.env.REACT_APP_ENV ? '' : 'dev'}
          </span>
        </Link>
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {/* <Link to="/" className="nav-link">
            Home
          </Link> */}
          {isSignedIn && (
            <li className="nav-item dropdown">
              <Link
                to="./heating"
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Heating
              </Link>
              <ul className="dropdown-menu" id="heatingDropdown">
                <li>
                  <Link to="./heating/heatingdaily" className="dropdown-item">
                    Heating Daily Records
                  </Link>
                </li>
                <li>
                  <Link to="./heating/temperatures" className="dropdown-item">
                    Heating Temperatures
                  </Link>
                </li>
                <li>
                  <Link to="./heating/seasonsum" className="dropdown-item">
                    Season Summary
                  </Link>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};
