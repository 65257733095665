import { configureStore } from '@reduxjs/toolkit';
import heatingReducer from './heatingSlice';
import questionsReducer from './questionsSlice';
import signinReducer from './signinSlice';
import sewingReducer from './sewingSlice';

export const store = configureStore({
  reducer: {
    heating: heatingReducer,
    questions: questionsReducer,
    signin: signinReducer,
    sewing: sewingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
